<template>
  <div>
    <wasteproduction-add></wasteproduction-add>
  </div>
</template>

<script>
import WasteproductionAdd from "@/components/ecologist/wasteproduction/wasteproductionAdd";
export default {
  name: "wasteproductionAddView",
  components: { WasteproductionAdd },
};
</script>

<style scoped></style>
