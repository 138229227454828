<template>
  <div>
    <h2>Отход производства</h2>

    <div class="search">
      <h4 style="margin-top: 0">Поиск отхода</h4>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-select
            clearable
            @change="searchWasteByCode"
            class="searchPole"
            v-model="search.code"
            filterable
            remote
            value-key="id"
            reserve-keyword
            placeholder="Код отхода"
            :remote-method="searchCode"
            :loading="search.loadingCodes"
          >
            <el-option
              v-for="item in ss01CodeList"
              :key="item.id"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="12">
          <el-select
            clearable
            @change="searchWasteByName"
            class="searchPole"
            v-model="search.name"
            filterable
            remote
            value-key="id"
            reserve-keyword
            placeholder="Наименование"
            :remote-method="searchWasteName"
            :loading="search.loadingNames"
          >
            <el-option
              v-for="item in wasteNames"
              :key="item.id"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>

    <h2>Информация об отходе</h2>
    <el-form
      v-if="
        addWasteProductionForm.ss01WasteDictionaryDTO.ss01DictionaryId !==
        undefined
      "
      :model="addWasteProductionForm"
      ref="formedForm"
      :rules="rules"
      label-position="top"
      label-width="120px"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="Код отхода">
            <el-input
              disabled
              v-model="addWasteProductionForm.ss01WasteDictionaryDTO.wasteCode"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Наименование отхода">
            <el-input
              disabled
              v-model="addWasteProductionForm.ss01WasteDictionaryDTO.wasteName"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="Степень опасности">
            <el-select
              clearable
              disabled
              class="searchPole"
              v-model="
                addWasteProductionForm.ss01WasteDictionaryDTO
                  .dangerDegreeClassifierDTO
              "
              filterable
              value-key="id"
              placeholder="Степень опасности"
            >
              <el-option
                v-for="item in dangerDegree"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-select
              clearable
              class="searchPole"
              v-model="
                addWasteProductionForm.organizationWastePSS01DangerDegreeInfo
              "
              filterable
              value-key="id"
              placeholder="Степень опасности"
            >
              <el-option
                v-for="item in dangerDegree"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="Координаты мест образования отходов на «Карте-схеме источников образования отходов»"
          >
            <el-input
              placeholder="Координаты"
              v-model="addWasteProductionForm.coordinates"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item
            label="Код вида отхода по классификации Базельской конвенции"
          >
            <el-input
              disabled
              v-model="
                addWasteProductionForm.ss01WasteDictionaryDTO
                  .bazelConvinceWasteCode
              "
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Код отхода по [3]">
            <el-input
              disabled
              v-model="
                addWasteProductionForm.ss01WasteDictionaryDTO
                  .wasteCodeByDecision2000
              "
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item label="Код физического состояния отхода">
            <el-select
              clearable
              class="searchPole"
              value-key="id"
              v-model="addWasteProductionForm.pss16PhysicalInfo"
              filterable
              placeholder="Выбрать"
            >
              <el-option
                v-for="item in pss16PhysicalInfoList"
                :key="item.id"
                :label="item.id + '-' + item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            label="Вторичный материальный ресурс "
            prop="pss04SecondaryInfo"
          >
            <el-select
              clearable
              class="searchPole"
              value-key="id"
              v-model="addWasteProductionForm.pss04SecondaryInfo"
              filterable
              placeholder="Выбрать"
            >
              <el-option
                v-for="item in pss04SecondaryInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label=" Ртутьсодержащие" prop="pss05MercuryInfo">
            <el-select
              clearable
              class="searchPole"
              value-key="id"
              v-model="addWasteProductionForm.pss05MercuryInfo"
              filterable
              placeholder="Выбрать"
            >
              <el-option
                v-for="item in pss05MercuryInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="ТБО ">
            <el-switch
              v-model="addWasteProductionForm.tbo"
              active-text="Да"
              :active-value="true"
              :inactive-value="false"
              inactive-text="Нет"
            >
            </el-switch>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="Норматив образования отхода">
            <el-input
              placeholder="Норматив образования отхода"
              v-model="addWasteProductionForm.wasteGenerationStandardMeasure"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="ед. измерения">
            <el-input
              placeholder="ед. измерения"
              v-model="addWasteProductionForm.wasteGenerationStandard"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item
            label="Физико-химическая характеристика отходов, состав отходов по компонентам, наименование"
          >
            <el-input
              placeholder="Физико-химическая характеристика отходов, состав отходов по компонентам, наименование"
              v-model="addWasteProductionForm.physChemCharacteristicName"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="Физико-химическая характеристика отходов, состав отходов по компонентам, содержание, %"
          >
            <el-input
              placeholder="Физико-химическая характеристика отходов, состав отходов по компонентам, содержание, %"
              v-model="addWasteProductionForm.physChemCharacteristicPercent"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <h4>Опасные свойства отхода</h4>

      <el-form-item label="">
        <el-select
          clearable
          class="searchPole"
          value-key="id"
          v-model="addWasteProductionForm.pss03DangerInfo"
          filterable
          placeholder="Опасные свойства"
        >
          <el-option
            v-for="item in pss03DangerInfoList"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Единицы измерения">
        <el-select
          class="searchPole"
          value-key="id"
          v-model="addWasteProductionForm.pss93UnitsInfo"
          filterable
          placeholder="Единицы измерения"
        >
          <el-option
            v-for="item in pss93UnitsInfoList"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-row :gutter="10">
        <el-form-item
          label="Количество образующихся отходов производства, подлежащих хранению"
        >
          <el-col :span="18">
            <el-input
              placeholder="Количество образующихся отходов производства, подлежащих хранению"
              v-model="addWasteProductionForm.storeLimit"
            ></el-input>
          </el-col>
          <el-col :span="4">
            <el-input
              disabled
              placeholder="Выберите ед. измерения"
              v-model="addWasteProductionForm.pss93UnitsInfo.name"
            ></el-input>
          </el-col>
          <el-col :span="2">
            <input type="checkbox" id="checkboxstoreLimitYear" v-model="addWasteProductionForm.storeLimitYear">
            <label for="checkboxstoreLimitYear">Годовой лимит</label>
          </el-col>
        </el-form-item>
      </el-row>

      <el-row :gutter="10">
        <el-form-item
          label="Количество образующихся отходов производства, подлежащих захоронению"
        >
          <el-col :span="20">
            <el-input
              placeholder="Количество образующихся отходов производства, подлежащих захоронению"
              v-model="addWasteProductionForm.burialLimit"
            ></el-input>
          </el-col>
          <el-col :span="4">
            <el-input
              disabled
              placeholder="Выберите ед. измерения"
              v-model="addWasteProductionForm.pss93UnitsInfo.name"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-row>

      <el-row :gutter="10">
        <el-form-item
          label="Количество отходов производства, хранимых для вывоза одной транспортной единицей"
        >
          <el-col :span="20">
            <el-input
              placeholder="Количество отходов производства, хранимых для вывоза одной транспортной единицей"
              v-model="addWasteProductionForm.transferUnitAmount"
            ></el-input>
          </el-col>
          <el-col :span="4">
            <el-input
              disabled
              placeholder="Выберите ед. измерения"
              v-model="addWasteProductionForm.pss93UnitsInfo.name"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-row>

      <h4>Данные для первого входа</h4>

      <el-row :gutter="10">
        <el-form-item label="Хранилось на начало года">
          <el-col :span="8">
            <el-input
              placeholder="Хранилось на начало года"
              v-model="addWasteProductionForm.storedForTheBeginningOfYear"
            ></el-input>
          </el-col>
          <el-col :span="4">
            <el-input
              disabled
              placeholder="Выберите ед. измерения"
              v-model="addWasteProductionForm.pss93UnitsInfo.name"
            ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="Образовано">
          <el-col :span="8">
            <el-input
              placeholder="Образовано"
              v-model="addWasteProductionForm.formed"
            ></el-input>
          </el-col>
          <el-col :span="4">
            <el-input
              disabled
              placeholder="Выберите ед. измерения"
              v-model="addWasteProductionForm.pss93UnitsInfo.name"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-row>

      <el-row :gutter="10"> </el-row>

      <el-row :gutter="10">
        <el-col :span="16">
          <el-form-item label="Поступило от юр. лиц">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-input
                  placeholder="Поступило от юр. лиц"
                  v-model="addWasteProductionForm.received"
                  @change="rules.pss17ReceiptReasonInfo[0].required = changeValueRules(addWasteProductionForm.received)"
                ></el-input>
              </el-col>
              <el-col :span="6">
                <el-input
                  disabled
                  placeholder="Выберите ед. измерения"
                  v-model="addWasteProductionForm.pss93UnitsInfo.name"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="pss17ReceiptReasonInfo" label="Наименование причины поступления от юр. лиц">
            <el-select
            clearable
              class="searchPole"
              value-key="id"
              v-model="addWasteProductionForm.pss17ReceiptReasonInfo"
              filterable
              placeholder="Наименование причины"
            >
              <el-option
                v-for="item in pss17ReceiptReasonInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="16">
          <el-form-item label="Поступило от юр. лиц 2">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-input
                  placeholder="Поступило от юр. лиц 2"
                  v-model="addWasteProductionForm.received2"
                  @change="rules.pss17ReceiptReasonInfo2[0].required = changeValueRules(addWasteProductionForm.received2)"
                ></el-input>
              </el-col>
              <el-col :span="6">
                <el-input
                  disabled
                  placeholder="Выберите ед. измерения"
                  v-model="addWasteProductionForm.pss93UnitsInfo.name"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item  prop="pss17ReceiptReasonInfo2" label="Наименование причины поступления от юр. лиц 2">
            <el-select
            clearable
              class="searchPole"
              value-key="id"
              v-model="addWasteProductionForm.pss17ReceiptReasonInfo2"
              filterable
              placeholder="Наименование причины"
            >
              <el-option
                v-for="item in pss17ReceiptReasonInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="16">
          <el-form-item label="Из них по импорту">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-input
                  placeholder="Из них по импорту"
                  v-model="addWasteProductionForm.receivedimp"
                ></el-input>
              </el-col>
              <el-col :span="6">
                <el-input
                  disabled
                  placeholder="Выберите ед. измерения"
                  v-model="addWasteProductionForm.pss93UnitsInfo.name"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="16">
          <el-form-item label="Реализовано от физ. лиц.">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-input
                  placeholder="Реализовано от физ. лиц."
                  v-model="addWasteProductionForm.receivedfz"
                  @change="rules.pss17ReceiptReasonInfofz[0].required = changeValueRules(addWasteProductionForm.receivedfz)"
                ></el-input>
              </el-col>
              <el-col :span="6">
                <el-input
                  disabled
                  placeholder="Выберите ед. измерения"
                  v-model="addWasteProductionForm.pss93UnitsInfo.name"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item  prop="pss17ReceiptReasonInfofz" label="Наименование причины поступления от физ. лиц.">
            <el-select
            clearable
              class="searchPole"
              value-key="id"
              v-model="addWasteProductionForm.pss17ReceiptReasonInfofz"
              filterable
              placeholder="Наименование причины"
            >
              <el-option
                v-for="item in pss17ReceiptReasonInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="Передано">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-input
                  placeholder="Передано"
                  v-model="addWasteProductionForm.transferred"
                  @change="rules.pss18TransferReasonInfo[0].required = changeValueRules(addWasteProductionForm.transferred)"
                ></el-input>
              </el-col>
              <el-col :span="6">
                <el-input
                  disabled
                  placeholder="Выберите ед. измерения"
                  v-model="addWasteProductionForm.pss93UnitsInfo.name"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item  prop="pss18TransferReasonInfo" label="Причина операции передачи">
            <el-select
            clearable
              @change="rules.pss21TransferObjectInfo[0].required = addWasteProductionForm.pss18TransferReasonInfo == null ? false : (addWasteProductionForm.pss18TransferReasonInfo.id == 6 ? true : false)"
              class="searchPole"
              value-key="id"
              placeholder="Причина передачи"
              v-model="addWasteProductionForm.pss18TransferReasonInfo"
              filterable
            >
              <el-option
                v-for="item in pss18TransferReasonInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item  prop="pss21TransferObjectInfo" label="Объект захоронения">
            <el-select
            clearable
              :disabled="addWasteProductionForm.pss18TransferReasonInfo == null ? false : (addWasteProductionForm.pss18TransferReasonInfo.id!=6)"
              placeholder="Объект захоронения"
              class="searchPole"
              value-key="id"
              v-model="addWasteProductionForm.pss21TransferObjectInfo"
              filterable
            >
              <el-option
                v-for="item in pss21BurialObjectInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="16">
          <el-form-item label="Передано по экспорту">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-input
                  placeholder="Передано по экспорту"
                  v-model="addWasteProductionForm.transferredexp"
                ></el-input>
              </el-col>
              <el-col :span="6">
                <el-input
                  disabled
                  placeholder="Выберите ед. измерения"
                  v-model="addWasteProductionForm.pss93UnitsInfo.name"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="16">
          <el-form-item label="Передано физ. лицам">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-input
                  placeholder="Передано физ. лицам"
                  v-model="addWasteProductionForm.transferredfz"
                  @change="rules.pss18TransferReasonInfofz[0].required = changeValueRules(addWasteProductionForm.transferredfz)"
                ></el-input>
              </el-col>
              <el-col :span="6">
                <el-input
                  disabled
                  placeholder="Выберите ед. измерения"
                  v-model="addWasteProductionForm.pss93UnitsInfo.name"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item  prop="pss18TransferReasonInfofz" label="Причина операции передачи">
            <el-select
              clearable
              class="searchPole"
              value-key="id"
              placeholder="Причина передачи"
              v-model="addWasteProductionForm.pss18TransferReasonInfofz"
              filterable
            >
              <el-option
                v-for="item in pss18TransferReasonInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      

      <el-row :gutter="10">
        <el-col :span="16">
          <el-form-item label="Использовано">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-input
                  placeholder="Использовано"
                  v-model="addWasteProductionForm.used"
                  @change="rules.pss19DirectionOfUseInfo[0].required = changeValueRules(addWasteProductionForm.used)"
                ></el-input>
              </el-col>
              <el-col :span="6">
                <el-input
                  disabled
                  placeholder="Выберите ед. измерения"
                  v-model="addWasteProductionForm.pss93UnitsInfo.name"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item  prop="pss19DirectionOfUseInfo" label="Наименование направления использования">
            <el-select
            clearable
              placeholder="Наименование направления"
              class="searchPole"
              value-key="id"
              v-model="addWasteProductionForm.pss19DirectionOfUseInfo"
              filterable
            >
              <el-option
                v-for="item in pss19DirectionOfUseInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="16">
          <el-form-item label="Обезврежено">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-input
                  placeholder="Обезврежено"
                  v-model="addWasteProductionForm.neutralized"
                  @change="rules.pss20NeutralizeMethodInfo[0].required = changeValueRules(addWasteProductionForm.neutralized)"
                ></el-input>
              </el-col>
              <el-col :span="6">
                <el-input
                  disabled
                  placeholder="Выберите ед. измерения"
                  v-model="addWasteProductionForm.pss93UnitsInfo.name"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="pss20NeutralizeMethodInfo" label="Наименование способа обезвреживания">
            <el-select
            clearable
              placeholder="Наименование способа"
              class="searchPole"
              value-key="id"
              v-model="addWasteProductionForm.pss20NeutralizeMethodInfo"
              filterable
            >
              <el-option
                v-for="item in pss20NeutralizeMethodInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="16">
          <el-form-item label="Захоронено">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-input
                  placeholder="Захоронено"
                  v-model="addWasteProductionForm.buried"
                  @change="rules.pss21BurialObjectInfo[0].required = changeValueRules(addWasteProductionForm.buried)"
                ></el-input>
              </el-col>
              <el-col :span="6">
                <el-input
                  disabled
                  placeholder="Выберите ед. измерения"
                  v-model="addWasteProductionForm.pss93UnitsInfo.name"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item  prop="pss21BurialObjectInfo" label="Объект захоронения">
            <el-select
            clearable
              placeholder="Объект захоронения"
              class="searchPole"
              value-key="id"
              v-model="addWasteProductionForm.pss21BurialObjectInfo"
              filterable
            >
              <el-option
                v-for="item in pss21BurialObjectInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="16">
          <el-form-item label="Направлено на объекты хранения">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-input
                  placeholder="Направлено на объекты хранения"
                  v-model="addWasteProductionForm.sentForStorage"
                  @change="rules.pss22StoredObjectInfo[0].required = changeValueRules(addWasteProductionForm.sentForStorage)"
                ></el-input>
              </el-col>
              <el-col :span="6">
                <el-input
                  disabled
                  placeholder="Выберите ед. измерения"
                  v-model="addWasteProductionForm.pss93UnitsInfo.name"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item  prop="pss22StoredObjectInfo" label="Наименование объекта хранения">
            <el-select
            clearable
              placeholder="Наименование объекта"
              class="searchPole"
              value-key="id"
              v-model="addWasteProductionForm.pss22StoredObjectInfo"
              filterable
            >
              <el-option
                v-for="item in pss22StoredObjectInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item
            prop="organizationInfo"
            label="Объект / Филиал / Подразделение"
          >
            <el-select
              style="width: 100%"
              clearable
              value-key="id"
              v-model="addWasteProductionForm.organizationInfo"
              filterable
              placeholder="Выберите"
            >
              <el-option
                v-for="item in organizationHierarchyByType"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row >
        <el-date-picker 
                  v-model="addWasteProductionForm.dateFrom"
                  type="date"
                  placeholder = "Дата начала работы"
                  value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-row>
      <br>
      <el-row>
        <input type="checkbox" id="checkboxdatEd" v-model="addWasteProductionForm.datEd">
        <label for="checkboxdatEd">Устаревшее</label>
      </el-row>
      <br>
      <el-row >
        <el-date-picker 
                  v-model="addWasteProductionForm.dateTo"
                  type="date"
                  :disabled="!addWasteProductionForm.datEd"
                  placeholder = "Дата окончания работы"
                  value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-row>
      <el-row>
        <el-col>
          <input type="checkbox" id="checkboxremoveFromAir" v-model="addWasteProductionForm.removeFromAir">
          <label for="checkboxremoveFromAir">Не отображать отход в отчете 1-отходы</label>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-button @click="addWaste" type="primary">Сохранить</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import notification from "@/mixins/notification";

export default {
  name: "wasteproductionAdd",
  mixins: [notification],
  data() {
    return {
      rules: {
        pss04SecondaryInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        pss05MercuryInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        pss17ReceiptReasonInfo: [
          {
            required: false,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        pss17ReceiptReasonInfo2: [
          {
            required: false,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        pss17ReceiptReasonInfofz: [
          {
            required: false,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        pss18TransferReasonInfo: [
          {
            required: false,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        pss21TransferObjectInfo: [
          {
            required: false,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        pss18TransferReasonInfofz: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        pss19DirectionOfUseInfo: [
          {
            required: false,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        pss20NeutralizeMethodInfo: [
          {
            required: false,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        pss21BurialObjectInfo: [
          {
            required: false,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        pss22StoredObjectInfo: [
          {
            required: false,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],   
        

        organizationInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        
      },
      typeWriteDangerDegree: false,
      pss16PhysicalInfoList: null,
      pss04SecondaryInfoList: null,
      pss05MercuryInfoList: null,
      pss93UnitsInfoList: null,
      pss17ReceiptReasonInfoList: null,
      pss18TransferReasonInfoList: null,
      pss19DirectionOfUseInfoList: null,
      pss20NeutralizeMethodInfoList: null,
      pss21BurialObjectInfoList: null,
      pss22StoredObjectInfoList: null,
      pss03DangerInfoList: null,
      dangerDegree: null,
      search: {
        code: null,
        name: null,
        loadingCodes: false,
        loadingNames: false,
      },
      addWasteProductionForm: {
        datEd:false,
        dateFrom:null,
        dateTo:null,
        storeLimitYear: false,
        removeFromAir:false,
        organizationInfo: null,
        ss01WasteDictionaryDTO: {
          dangerDegreeClassifierDTO: {
            id: null,
            name: null,
          },
          organizationWastePSS01DangerDegreeInfo: null,
        },
        pss16PhysicalInfo: {
          id: null,
          name: null,
        },
        pss04SecondaryInfo: null,
        pss05MercuryInfo: null,
        tbo: false,
        wasteGenerationStandardMeasure: null,
        wasteGenerationStandard: null,
        physChemCharacteristicName: null,
        physChemCharacteristicPercent: null,
        pss03DangerInfo: {
          id: null,
          name: null,
        },
        storeLimit: null,
        burialLimit: null,
        transferUnitAmount: null,
        storedForTheBeginningOfYear: null,
        formed: null,
        received: null,
        transferred: null,
        transferredfz: null,
        neutralized: null,
        buried: null,
        sentForStorage: null,
        pss17ReceiptReasonInfo: {
          id: null,
          name: null,
        },
        pss17ReceiptReasonInfo2: {
          id: null,
          name: null,
        },
        pss17ReceiptReasonInfofz: {
          id: null,
          name: null,
        },
        pss18TransferReasonInfo: {
          id: null,
          name: null,
        },
        pss18TransferReasonInfofz: {
          id: null,
          name: null,
        },
        pss19DirectionOfUseInfo: {
          id: null,
          name: null,
        },
        pss20NeutralizeMethodInfo: {
          id: null,
          name: null,
        },
        pss21BurialObjectInfo: {
          id: null,
          name: null,
        },
        pss21TransferObjectInfo:{
          id: null,
          name: null,
        },
        pss22StoredObjectInfo: {
          id: null,
          name: null,
        },
        pss93UnitsInfo: {
          id: null,
          name: null,
        },
      },
      code: null,
      name: null,
      dangerClass: null,
      organizationalUnits: null,
    };
  },

  computed: {
    ...mapGetters({
      ss01CodeList: "GETSSS01CODELIST",
      wasteNames: "GETWASTENAMES",
      foundedWaste: "GETSS01DICTIONARY",
      organizationHierarchyByType: "GETORGANIZATIHIERARCHYBYTYPE",
    }),
  },

  methods: {
    getOrganizationHierarchyByType() {
      this.$store.dispatch("getOrganizationHierarchyByType", {
        type: 2,
      });
    },

    getPss16PhysicalInfoList() {
      this.getSubClassifierById(16).then((response) => {
        this.pss16PhysicalInfoList = response.data.content;
      });
    },

    getPss04SecondaryInfoList() {
      this.getSubClassifierById(4).then((response) => {
        this.pss04SecondaryInfoList = response.data.content;
      });
    },

    getPss05MercuryInfoList() {
      this.getSubClassifierById(5).then((response) => {
        this.pss05MercuryInfoList = response.data.content;
      });
    },

    getPss93UnitsInfoList() {
      this.getSubClassifierById(93).then((response) => {
        this.pss93UnitsInfoList = response.data.content;
      });
    },

    getPss17ReceiptReasonInfoList() {
      this.getSubClassifierById(17).then((response) => {
        this.pss17ReceiptReasonInfoList = response.data.content;
      });
    },

    getPss18TransferReasonInfoList() {
      this.getSubClassifierById(18).then((response) => {
        this.pss18TransferReasonInfoList = response.data.content;
      });
    },

    getPss19DirectionOfUseInfoList() {
      this.getSubClassifierById(19).then((response) => {
        this.pss19DirectionOfUseInfoList = response.data.content;
      });
    },

    getPss20NeutralizeMethodInfoList() {
      this.getSubClassifierById(20).then((response) => {
        this.pss20NeutralizeMethodInfoList = response.data.content;
      });
    },

    getPss21BurialObjectInfoList() {
      this.getSubClassifierById(21).then((response) => {
        this.pss21BurialObjectInfoList = response.data.content;
      });
    },

    getPss22StoredObjectInfoList() {
      this.getSubClassifierById(22).then((response) => {
        this.pss22StoredObjectInfoList = response.data.content;
      });
    },

    getPss03DangerInfoList() {
      this.getSubClassifierById(3).then((response) => {
        this.pss03DangerInfoList = response.data.content;
      });
    },

    getDangerDegree() {
      this.getSubClassifierById(1).then((response) => {
        this.dangerDegree = response.data.content;
      });
    },

    searchCode(query) {
      this.getCodeList(query);
    },

    searchWasteName(query) {
      this.getWasteNames(query);
    },

    async getCodeList(query) {
      this.search.loadingCodes = true;
      await this.$store.dispatch("getCodeList", query);
      this.search.loadingCodes = false;
    },

    async getWasteNames(query) {
      this.search.loadingNames = true;
      await this.$store.dispatch("getWasteNames", query);
      this.search.loadingNames = false;
    },

    async searchWasteByCode() {
      await this.$store.dispatch(
        "findSS01DictionaryValueByClassifierId",
        this.search.code.id
      );
      this.addWasteProductionForm.ss01WasteDictionaryDTO =
        this.foundedWaste.content[0];
    },

    async searchWasteByName() {
      await this.$store.dispatch(
        "findSS01DictionaryValueByClassifierId",
        this.search.name.id
      );
      this.addWasteProductionForm.ss01WasteDictionaryDTO =
        this.foundedWaste.content[0];
    },

    async getSubClassifierById(id) {
      let data = {
        id: id,
        size: 100,
        page: 0,
      };
      return await this.$store.dispatch(
        "getAllDataSubClassifierForSelect",
        data
      );
    },

    searchWaste() {},

    addWaste() {
      this.clearForm();
      console.log(this.rules);
      console.log(this);
      this.$refs["formedForm"].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("createOrganizationWaste", this.addWasteProductionForm)
            .then(() => {
              this.notification("Успешно", "Отход добавлен", "success");
              this.$router.push({path:"/wasteproduction/main",
              query: { size: this.$route.query.size,page:this.$route.query.page }});
            });
        } else {
          this.notification(
            "Ошибка валидации",
            "Заполните обязательные поля",
            "warning"
          );
        }
      });
    },
    changeValueRules(value){
      if (value == "" || value == null)
      return false;
      else 
      return true;
    },
    RulsChange(){
      this.rules.pss17ReceiptReasonInfo[0].required = this.addWasteProductionForm.received == null || this.addWasteProductionForm.received == "" ? false : true;
      this.rules.pss17ReceiptReasonInfo2[0].required = this.addWasteProductionForm.received2 == null || this.addWasteProductionForm.received2 == "" ? false : true;
      this.rules.pss17ReceiptReasonInfofz[0].required = this.addWasteProductionForm.receivedfz == null || this.addWasteProductionForm.receivedfz == "" ? false : true;
      this.rules.pss18TransferReasonInfo[0].required = this.addWasteProductionForm.transferred == null || this.addWasteProductionForm.transferred == "" ? false : true;
      this.rules.pss18TransferReasonInfofz[0].required = this.addWasteProductionForm.transferredfz == null || this.addWasteProductionForm.transferredfz == "" ? false : true;
      this.rules.pss21TransferObjectInfo[0].required = this.addWasteProductionForm.pss18TransferReasonInfo.id!=6 || this.addWasteProductionForm.transferred == "" || this.addWasteProductionForm.received == null ? false : true;
      this.rules.pss19DirectionOfUseInfo[0].required = this.addWasteProductionForm.used == null || this.addWasteProductionForm.used == "" ? false : true;
      this.rules.pss20NeutralizeMethodInfo[0].required = this.addWasteProductionForm.neutralized == null || this.addWasteProductionForm.neutralized == "" ? false : true;
      this.rules.pss21BurialObjectInfo[0].required = this.addWasteProductionForm.buried == null || this.addWasteProductionForm.buried == "" ? false : true;
      this.rules.pss22StoredObjectInfo[0].required = this.addWasteProductionForm.sentForStorage == null || this.addWasteProductionForm.sentForStorage == "" ? false : true;
      if(this.rules.pss17ReceiptReasonInfo[0].required==false)
        this.addWasteProductionForm.pss17ReceiptReasonInfo = null;
      if(this.rules.pss17ReceiptReasonInfo2[0].required==false)
        this.addWasteProductionForm.pss17ReceiptReasonInfo2 = null;
      if(this.rules.pss17ReceiptReasonInfofz[0].required==false)
        this.addWasteProductionForm.pss17ReceiptReasonInfofz = null;
      if(this.rules.pss18TransferReasonInfo[0].required==false)
        this.addWasteProductionForm.pss18TransferReasonInfo = null;
      if(this.rules.pss18TransferReasonInfofz[0].required==false || this.addWasteProductionForm.pss18TransferReasonInfofz.id == null)
        this.addWasteProductionForm.pss18TransferReasonInfofz = null;
      if(this.rules.pss21TransferObjectInfo[0].required==false)
        this.addWasteProductionForm.pss21TransferObjectInfo = null;
      if(this.rules.pss19DirectionOfUseInfo[0].required==false)
        this.addWasteProductionForm.pss19DirectionOfUseInfo = null;
      if(this.rules.pss20NeutralizeMethodInfo[0].required==false)
        this.addWasteProductionForm.pss20NeutralizeMethodInfo = null;
      if(this.rules.pss21BurialObjectInfo[0].required==false)
        this.addWasteProductionForm.pss21BurialObjectInfo = null;
      if(this.rules.pss22StoredObjectInfo[0].required==false)
        this.addWasteProductionForm.pss22StoredObjectInfo = null;
    },
    clearForm(){
      if(this.addWasteProductionForm.pss17ReceiptReasonInfo=="") this.addWasteProductionForm.pss17ReceiptReasonInfo = null;
      if(this.addWasteProductionForm.pss17ReceiptReasonInfo2=="") this.addWasteProductionForm.pss17ReceiptReasonInfo2 = null;
      if(this.addWasteProductionForm.pss17ReceiptReasonInfofz=="") this.addWasteProductionForm.pss17ReceiptReasonInfofz = null;
      if(this.addWasteProductionForm.pss18TransferReasonInfo=="") this.addWasteProductionForm.pss18TransferReasonInfo = null;
      if(this.addWasteProductionForm.pss18TransferReasonInfofz=="") this.addWasteProductionForm.pss18TransferReasonInfofz = null;
      if(this.addWasteProductionForm.pss21TransferObjectInfo=="") this.addWasteProductionForm.pss21TransferObjectInfo = null;
      if(this.addWasteProductionForm.pss19DirectionOfUseInfo=="") this.addWasteProductionForm.pss19DirectionOfUseInfo = null;
      if(this.addWasteProductionForm.pss20NeutralizeMethodInfo=="") this.addWasteProductionForm.pss20NeutralizeMethodInfo = null;
      if(this.addWasteProductionForm.pss21BurialObjectInfo=="") this.addWasteProductionForm.pss21BurialObjectInfo = null;
      if(this.addWasteProductionForm.pss22StoredObjectInfo=="") this.addWasteProductionForm.pss22StoredObjectInfo = null;
      if(this.addWasteProductionForm.pss03DangerInfo=="") this.addWasteProductionForm.pss03DangerInfo = {id: null,name: null};
    }
  },

  mounted() {
    this.getPss16PhysicalInfoList();
    this.getPss04SecondaryInfoList();
    this.getPss05MercuryInfoList();
    this.getPss93UnitsInfoList();
    this.getPss17ReceiptReasonInfoList();
    this.getPss18TransferReasonInfoList();
    this.getPss19DirectionOfUseInfoList();
    this.getPss20NeutralizeMethodInfoList();
    this.getPss21BurialObjectInfoList();
    this.getPss22StoredObjectInfoList();
    this.getPss03DangerInfoList();
    this.getDangerDegree();
    this.getOrganizationHierarchyByType();
    this.RulsChange();
    console.log(this);
  },
};
</script>

<style>
.el-scrollbar {
  max-width: 70vw !important;
}
</style>
